












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
    model: {
        prop: "model",
        event: "inputChanged"
    }
})
export default class RegistrationNumberTextField extends Vue {
    // Fields

    // Component Properties:
    @Prop({ required: true })
    public model!: string | null;

    @Prop({ required: false })
    public solo?: boolean;

    @Prop({ required: false })
    public outlined?: boolean;

    @Prop({ required: false })
    public label?: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false })
    public busy?: boolean;

    // Getter & Setter
    public get input(): string | null {
        return this.model;
    }
    public set input(value: string | null) {
        this.fireInputChanged(value);
    }

    // Component lifecycle methods:

    // Methods:

    // Event Emitter:
    public fireInputChanged(value: string | null): void {
        this.$emit('inputChanged', value);
    }

}
